import { useControls, folder } from 'leva'
import { RigidBody } from '@react-three/rapier'
import { Sphere, Float } from '@react-three/drei'
import { Physics } from '@react-three/rapier'
import { useRef, useState, useEffect, useLayoutEffect } from 'react'
import gsap from 'gsap'

import Tree from './Tree.jsx'
import Cocoa from './Cocoa.jsx'
import Boat from './Boat.jsx'
import IntroText from './IntroText.jsx'
import Logo from './Logo.jsx'

import useSeaStore from '../../stores/useSea.jsx'
import useIntroStore from '../../stores/useIntro.jsx'
import useMainStore from '../../stores/useMain.jsx'
import useScreenStore from '../../stores/useScreen.jsx'
import useDebugStore from '../../stores/useDebug.jsx'
import useLightStore from '../../stores/useLight.jsx'
import { useTranslation } from 'react-i18next'

export default function Island(props) {

  const [t, i18n] = useTranslation("intro")

  const isDebug = useDebugStore((state) => state.isDebug)
  
  const [text1,         setText1]         = useState(true)
  const [text2,         setText2]         = useState(true)
  const [text3,         setText3]         = useState(false)
  const [text5,         setText5]         = useState(false)
  const [loadIsland,    setLoadIsland]    = useState(true)
  const [loadTree,      setLoadTree]      = useState(true)
  const [loadCacao,     setLoadCacao]     = useState(true)
  const [loadBoat,      setLoadBoat]      = useState(false)

  const introState            = useIntroStore((state) => state.introState)
  const incrementIntroState   = useIntroStore((state) => state.incrementIntroState)
  const updateIsReadyToScroll = useIntroStore((state) => state.updateIsReadyToScroll)
  const unsetIntro            = useIntroStore((state) => state.unsetIntro)

  const orientation           = useScreenStore((state) => state.orientation)
  const device                = useScreenStore((state) => state.device)
  
  const setSunDistance        = useLightStore((state) => state.setSunDistance)
  const setCloudsPositionY    = useLightStore((state) => state.setCloudsPositionY)
  
  const setMain               = useMainStore((state) => state.setMain)

  const posX = 0,
        posY = 0.4,
        posZ = 0,
        scale = 1,
        [pos2X, setPos2X] = useState((orientation == 'paysage') ? 2.87 : 0),
        pos2Y = 0,
        pos2Z = 0,
        [scale2, setScale2] = useState((orientation == 'paysage') ? 1 : 0.8),
        islandPosX = 0,
        islandPosY = -0.8,
        islandPosZ = 0,
        islandScaleX = 2,
        islandScaleY = 1,
        islandScaleZ = 2
  
  // LEVA CONTROLS
  /* const { _posX, _posY, _posZ, _scale } = isDebug ? useControls(
      {
        'Tree Island': folder(
          {'Group tree + island + text': folder(
            {
              _posX: {
                value: posX,
                min: -10,
                max: 10,
                step: .01
              },
              _posY: {
                value: posY,
                min: -1,
                max: 1,
                step: .01
              },
              _posZ: {
                value: posZ,
                min: -10,
                max: 10,
                step: .01
              },
              _scale: {
                value: scale,
                min: 0,
                max: 1,
                step: .01
              }
            },
            { collapsed: true }
          )},
          { collapsed: true }
            
        )
      }
  ) : 0 */

  /* const { _pos2X, _pos2Y, _pos2Z, _scale2 } = isDebug ? useControls(
      {
      'Tree Island': folder(
          {'Group tree + island': folder(
          {
              _pos2X: {
                value: pos2X,
                min: -10,
                max: 10,
                step: .01,
                onChange: (value) => { setPos2X(value) },
              },
              _pos2Y: {
                value: pos2Y,
                min: -10,
                max: 10,
                step: .01
              },
              _pos2Z: {
                value: pos2Z,
                min: -10,
                max: 10,
                step: .01,
              },
              _scale2: {
                value: scale2,
                min: 0,
                max: 1,
                step: .01,
                onChange: (value) => { setScale2(value) },
              }
          },
          { collapsed: true }
          )},
          { collapsed: true }
          
      )
      }
  ) : 0 */

  /* const { _islandPosX, _islandPosY, _islandPosZ, _islandScaleX, _islandScaleY, _islandScaleZ } = isDebug ? useControls(
      {
        'Tree Island': folder(
          {'Island': folder(
            {
              _islandPosX: {
                value: islandPosX,
                min: -10,
                max: 10,
                step: .1
              },
              _islandPosY: {
                value: islandPosY,
                min: -10,
                max: 10,
                step: .1
              },
              _islandPosZ: {
                value: islandPosZ,
                min: -10,
                max: 10,
                step: .1
              },
              _islandScaleX: {
                value: islandScaleX,
                min: 0,
                max: 3,
                step: .01
              },
              _islandScaleY: {
                value: islandScaleY,
                min: 0,
                max: 3,
                step: .01
              },
              _islandScaleZ: {
                value: islandScaleZ,
                min: 0,
                max: 3,
                step: .01
              }
            },
            { collapsed: true }
          )},
          { collapsed: true }
            
        )
      }
  ) : 0 */

  // REF FOR CHILD COMPONENTS
  const treeRef = useRef()
  const podRef = useRef()
  const boatRef = useRef()
  const islandRef = useRef()
  const text5Ref = useRef()
  const logoRef = useRef()

  // ANIMATION
  /* const cameraPosX = useCameraStore((state) => state.cameraPosX)
  const cameraPosY = useCameraStore((state) => state.cameraPosY)
  const cameraPosZ = useCameraStore((state) => state.cameraPosZ)
  const cameraLookAtX = useCameraStore((state) => state.cameraLookAtX)
  const cameraLookAtY = useCameraStore((state) => state.cameraLookAtY)
  const cameraLookAtZ = useCameraStore((state) => state.cameraLookAtZ) */
  
  /* const uDepthColor = useSeaStore((state) => state.uDepthColor)
  const setDepthColor = useSeaStore((state) => state.setDepthColor)
  const uSurfaceColor = useSeaStore((state) => state.uSurfaceColor)
  const setSurfaceColor = useSeaStore((state) => state.setSurfaceColor) */

  useEffect(() => {
    
    if      (introState == 1) { anim1() }
    else if (introState == 2) { anim2() }
    else if (introState == 3) { anim3() }
    else if (introState == 4) { anim4() }
    else if (introState == 5) { anim5() }
      
  },[introState]) // Run this useEffect when introState changes

  // ANIMATION 1
  function anim1() {

    treeRef.current?.freePods()
    treeRef.current?.rotateTree()
    podRef.current?.rotatePod()
  }

  // ANIMATION 2
  function anim2() {

    setText1(false)
    setText3(true)
    setLoadBoat(true)
  }

  // ANIMATION 3
  function anim3() {

    setText2(false)
    boatRef.current?.boatIn()
    
    setTimeout(() => {
        setText3(false)
        setText5(true)
      },
      2000 //Delay
    )
  }

  // ANIMATION 4
  function anim4() {

    setText3(false)
    setText5(true)
    boatRef.current?.boatOut()
    
    const tl = gsap.timeline()

    tl.to( islandRef.current?.position,
      { y: -4, duration: 3, ease: 'ease.inOut' }, 2 )
    tl.to( islandRef.current?.rotation,
      { y: 50, duration: 5, ease: 'expo.in' }, 0 )
    tl.to( text5Ref.current?.position,
      { y: 0, duration: 2, ease: 'ease.Out' }, 2 )
    tl.to( logoRef.current?.position,
      { y: 0.9, duration: 2, ease: 'ease.Out' }, 2.5 )

    setTimeout(() =>
      { 
        setLoadCacao(false)
        setLoadIsland(false)
        setLoadTree(false)
      },
      5000 //Delay
    )
  }

  // ANIMATION 5
  function anim5() {
    const tl = gsap.timeline()

    // Clouds moving up
    const proxy1 = {cloudsPositionY: 30}

    tl.to(proxy1, {
        cloudsPositionY: 150,
        ease: "ease.inOut",
        duration: 1,
        onUpdate: () => setCloudsPositionY(proxy1.cloudsPositionY) // <-- update the real thing using React plumbing
    })

    // Sky getting white
    const proxy2 = {sunDistance: 1000}

    tl.to(
      proxy2,
      {
        sunDistance: 1,
        ease: "ease.inOut",
        duration: 1.5,
        onUpdate: () => setSunDistance(proxy2.sunDistance) // <-- update the real thing using React plumbing
      }, 0.5) // delay

    setTimeout(() => {
      unsetIntro()
      setMain()
    }, 3000)
  }
  
  function animLogo() {
    // Change color of sea to chocolate
    /* const proxy1 = {uSurfaceColor: uSurfaceColor}
    tl1.to(proxy1, {
        uSurfaceColor: '#93715b',
        ease: "none",
        duration: 10,
        onUpdate: () => setSurfaceColor(proxy1.uSurfaceColor) // <-- update the real thing using React plumbing
    }, 3)
    const proxy2 = {uDepthColor: uDepthColor}
    tl1.to(proxy2, {
      uDepthColor: '#3b1800',
        ease: "none",
        duration: 10,
        onUpdate: () => setDepthColor(proxy2.uDepthColor) // <-- update the real thing using React plumbing
    }, 3) */
  }


  // LISTEN SCROLL
  // UPDATE OF INTRO STATE isReadyToScroll
  useEffect(() => {
  
    window.addEventListener('wheel', incrementIntroState);
    updateIsReadyToScroll()
  
    return () => {
      window.removeEventListener('wheel', incrementIntroState)
    }
  }, [])
  
  // SET ORIENTATION AND DEVICE
  useEffect(() => {
    setPos2X((orientation == 'paysage') ? 2.87 : 0)
    setScale2((orientation == 'paysage') ? 1 : 0.8)
  }, [orientation, device])
  
    // RETURN
    return (
        <>
            <group position={ [posX, posY, posZ] } scale={ scale }>
                <group position={ [pos2X, pos2Y, pos2Z] } scale={ scale2 }>
                    {loadIsland && (
                      <group ref={islandRef}>
                          <Physics /* paused */ /* debug */>
                            {loadTree && (
                              <Tree ref={treeRef}/>
                            )}
                            <RigidBody type='fixed' colliders='hull' restitution={0.2}>
                              <Sphere
                                  receiveShadow
                                  position={ [islandPosX, islandPosY, islandPosZ] } 
                                  scale={ [islandScaleX, islandScaleY, islandScaleZ] }
                              >
                                  <meshStandardMaterial color="#008800"/>
                              </Sphere>
                            </RigidBody>
                          </Physics>
                          {loadCacao && (
                            <Float
                              speed={2} // Animation speed, defaults to 1
                              rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                              floatIntensity={0.5} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                              //floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                            >
                              <Cocoa ref={podRef}/>
                            </Float>
                          )}
                      </group>
                    )}
                    {loadBoat && (
                      <Float
                        speed={5} // Animation speed, defaults to 1
                        rotationIntensity={0.05} // XYZ rotation intensity, defaults to 1
                        floatIntensity={2} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                        floatingRange={[-0.05, 0.05]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                      >
                        <Boat ref={boatRef} />
                      </Float>
                    )}
                    
                    <group
                      ref={logoRef}
                      scale={ 0.5 }
                      position={ [2.5, -4, 5] }
                      rotation={
                        ( orientation == 'paysage' )
                          ? [-Math.PI/2, Math.PI, Math.PI/2+1]
                          : [-Math.PI/2-0.3, Math.PI-0.15, Math.PI/2+1+0.1]
                      }
                    >
                      <Logo/>
                    </group>
                    
                    
                </group>
                
                {text1 && <IntroText
                    posX={ ( orientation == 'paysage' ) ? -2  : 0   }
                    posY={ ( orientation == 'paysage' ) ? 2.5 : 5.5 }
                    posZ={ ( orientation == 'paysage' ) ? 0   : 0   }
                    rotationY={0}
                    color={'#006600'}
                    scale={ ( orientation == 'paysage' ) ? 0.4  : 0.25 }
                    lineHeight={0.65}
                    content={
                        ( orientation == 'paysage' )
                          ? [
                            t("text1.line1"),                 // "It all starts with a tree."
                            t("text1.line2"),                 // "A cacao tree!"
                            t("text1.line3") + " KAKAOARBO",  // "In Esperanto:" + " KAKAOARBO"
                            ""
                            ]
                          : [
                            t("text1.line1"),
                            t("text1.line2"),
                            t("text1.line3"),
                            "KAKAOARBO"
                            ]
                    }
                />}
                {text2 && <IntroText
                    posX={ ( orientation == 'paysage' ) ? -2  : 0   }
                    posY={ ( orientation == 'paysage' ) ? 3.5 : 5.7 }
                    posZ={ ( orientation == 'paysage' ) ? 0   : 0   }
                    rotationY={Math.PI}
                    color={'#006600'}
                    scale={ ( orientation == 'paysage' ) ? 0.4  : 0.25 }
                    lineHeight={ ( orientation == 'paysage' ) ? 0.65  : 0.5 }
                    content={
                        [
                        t("text2.line1"), // 'With the care from our Mother Earth,'
                        t("text2.line2"), // 'the sun, the rain,'
                        t("text2.line3"), // 'and together with the support of'
                        t("text2.line4"), // 'our partners cacao farmers,'
                        t("text2.line5"), // 'those trees will produce fruits.'
                        t("text2.line6")  // 'The cacao pods!'
                        ]
                    }                  
                />}
                {text3 && <IntroText
                    posX={ ( orientation == 'paysage' ) ? -2  : 0   }
                    posY={ ( orientation == 'paysage' ) ? 3.5 : 4.7 }
                    posZ={ ( orientation == 'paysage' ) ? 0   : 0   }
                    rotationY={0}
                    color={'#006600'}
                    scale={ ( orientation == 'paysage' ) ? 0.4  : 0.25 }
                    lineHeight={ ( orientation == 'paysage' ) ? 0.65  : 0.5 }
                    content={
                        [
                        t("text3.line1"), // 'Inside the pods: '
                        t("text3.line2"), // 'many fresh cacao beans!'
                        t("text3.line3"), // 'The farmers will ferment them,'
                        t("text3.line4"), // 'then let the sun drying them.'
                        ]
                    }                  
                />}
                <group ref={text5Ref} position={ [0, -7, 0] }>
                  {text5 && <IntroText
                      posX={ ( orientation == 'paysage' ) ? 0.5  : 0   }
                      posY={ ( orientation == 'paysage' ) ? 2    : 5.5 }
                      posZ={ ( orientation == 'paysage' ) ? 6.5  : 0   }
                      rotationY={0.7}
                      color={'#006600'}
                      scale={0.4}
                      lineHeight={0.65}
                      content={ 
                          ( orientation == 'paysage' )
                          ? [
                            t("text5.line1") + " " + t("text5.line2"), // 'I am glad to receive this gift from nature,',
                            t("text5.line3") + " " + t("text5.line4"), // 'and carefully transform it for you into',
                            t("text5.line5") + " " + t("text5.line6") + " " + t("text5.line7"), // 'delicious, ethical and sustainable chocolate.',
                            '',
                            '',
                            ''
                            ]
                          : [
                            t("text5.line1"), // 'I am glad to receive'
                            t("text5.line2"), // 'this gift from nature,'
                            t("text5.line3"), // 'and carefully transform it'
                            t("text5.line4") + " " + t("text5.line5"), // 'for you into delicious,'
                            t("text5.line6"), // 'ethical and'
                            t("text5.line7") // 'sustainable chocolate.'
                            ]
                      }                  
                  />}
                </group>
            </group>        
        </>
    )
}