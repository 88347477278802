import { changeLanguage } from "i18next"

export default function LangSwitcher() {
    const handleChangeLanguage = (lang) => {
        changeLanguage(lang)
        // Update session storage
        sessionStorage.setItem("lang", lang);
    }

    return (
        <>
            <button className="lang-flag en" onClick={ () => handleChangeLanguage("en") }></button>
            <button className="lang-flag fr" onClick={ () => handleChangeLanguage("fr") }></button>
        </>
    )
}