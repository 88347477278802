import { Canvas} from '@react-three/fiber'
import { Leva } from 'leva'

import Experience from './Experience.jsx'
import Interface from '../html/Interface.jsx'

import useDebugStore from '../../stores/useDebug.jsx'


export default function App3D() {

    const isDebug = useDebugStore((state) => state.isDebug)

    return (
        
        <main className='main3D'>
            {   //
                // WEBGL CANVAS
                //
                <section className='section3D'>
                    <Canvas 
                        shadows
                        className='canvas3D'
                        camera={{ near: 0.1, far: 100 }}
                        /* frameloop="demand" */
                        dpr={[0.8, 2]}
                    >
                        <Experience />
                    </Canvas>

                    {
                    //
                    // INTERFACE
                    //
                    }
                    <Interface />
                </section>
                
            }
            {   //
                // LEVA CONTAINER
                //
                isDebug && (
                <div className='leva-container' >
                    <Leva fill collapsed />
                </div>
                )
            }
        </main>
    )

}