import { create } from 'zustand'
    
const lightStore = (set) => ({

    // LIGHT CONTROLS
    lightX: 10,
    lightY: 10,
    lightZ: 10,

    sunDistance: 1000,
    cloudsPositionY: 30,

    // SETTERS
    setLightX: (value) => set({ lightX: value }),
    setLightY: (value) => set({ lightY: value }),
    setLightZ: (value) => set({ lightZ: value }),
    
    setSunDistance: (value) => set({ sunDistance: value }),
    setCloudsPositionY: (value) => set({ cloudsPositionY: value }),

    // EXAMPLES
    /* bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }), */
})

const useLightStore = create(lightStore)

export default useLightStore