/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 .\src\assets\3d\tiller\scene.gltf -r .src/components/ 
*/

import React, { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'

import TorusSpinningLight from './TorusSpinningLight.jsx'

import scene from './tiller.glb'


export default function Tiller(props) {
  const { nodes, materials } = useGLTF(scene)

  const viewport = useThree((state) => state.viewport)

  const tillerRef = useRef()

  // R3F ANIMATION
  const speedFactor = 0.2
  const rotationFactor = 1

  useFrame(({ clock }) => {
    tillerRef.current.rotation.z = Math.sin(clock.getElapsedTime() * speedFactor ) * 2 * Math.PI * rotationFactor
  })

  //RETURN
  return (
    <>
      <group {...props} dispose={null}
        scale={viewport.height / 10 / 4} /* equivalent to 10vh for an object size 4 in the scene at z = 0 */
        position={[viewport.width / 2 - 1 , viewport.height / 2 - 1 , 0.12]}
        rotation={[0,0,0]}
      >
        <mesh ref={tillerRef} castShadow geometry={nodes.Low001_Roda_0.geometry} material={materials.Roda} rotation={[0,0,0]/* [-Math.PI / 2, Math.PI / 2, 0] */} />
        <TorusSpinningLight radius={2} intensity={12} slowFactor={2}/>
      </group>
    </>
  )
}

useGLTF.preload(scene)