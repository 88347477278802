import { useTranslation } from "react-i18next"

import './css/templateStd.css'

// function that returns true if a value is an object
function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
}

// Create the proper elements of the page (p, img, ul, link, ...)
function Element({data}) {

    const mime = { // for picture files
        "webp": "image/webp",
        "jpg": "image/jpeg",
        "jpeg": "image/jpeg",
        "bmp": "image/bmp",
        "gif": "image/gif",
        "png": "image/png",
        "svg": "image/svg+xml",
        "tif": "image/tiff",
        "tiff": "image/tiff"
    }
    
    if ( isObject(data) ) {
        const type = data.type

        switch(type) {
            case "ul":
                return (
                    data.data.map( (li, i) =>
                        (<li> {li} </li>)
                    )
                )
            case "picture":
                return (
                    <>
                        <picture>
                            <source srcset={data.name + "." + data.source} type={mime[data.source]}/>
                            <source srcset={data.name + "." + data.fallback} type={mime[data.fallback]}/>
                            <img className={"picture" + ( ("class" in data) ? (" " + data.class) : "" ) } alt={data.alt}/>
                        </picture>
                        <div className="picture"><p className="picture">{data.text}</p></div>
                    </>

                )
            case "table":
                return (
                    <>
                        <div className="table"><p className="table">{data.title}</p></div>
                        <table className="table">
                            <tbody className="table">
                                {data.data.map( (tr, i) =>
                                    (<tr key={i} className="table">
                                        {tr.map( (td, j) =>
                                            (<td key={j} className="table">{td}</td>)
                                        )}
                                    </tr>)
                                )}
                            </tbody>
                        </table>
                    </>

                )
            case "subsection":
                return (
                    data.content.map( (section, i) => // map sections
                        (
                            <>
                                <h3>{section.title}</h3>
                                {section.text.map( (element, i2) =>
                                    (<Element data={element} />)
                                )}
                            </>
                        )
                    )
                )
            default:
                return (<p>error: ELEMENT NOT SUPPORTED by templateStd</p>)
            
        } 
        return <p>something else</p>
    }
    
    return <p> {data} </p>
    
}

// template function for standard pages
export default function TemplateStd( { page } ) {

    const [t, i18n] = useTranslation(page)
    const title = t("title") // returns translated title of the page
    const content = t("content", { returnObjects: true }) // returns an object of sections with all translated elements of the page
    // RETURN
    return (
        <>
            <h1>{title}</h1>
            {content.map( (section, i) => // map sections
                (
                    <>
                        <h2 key={i}>{section.title}</h2>
                        {section.text.map( (element, i2) =>
                            (<Element key={i2} data={element} />)
                        )}
                    </>
                    
                )
            )}
        </>
    )
}