import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useState } from "react"

import LangSwitcher from "./langSwitcher.jsx"

import useIntroStore from '../../stores/useIntro.jsx'
import useMainStore from '../../stores/useMain.jsx'

export default function Header() {

    const [t, i18n] = useTranslation("global")
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const playIntro = () => {
        useIntroStore.persist.clearStorage('introStore-storage')
        useMainStore.persist.clearStorage('mainStore-storage')
        window.location.reload()
    }

    return (
        <>
            <nav className="nav">
                <div className="nav menu" onClick={ () => { setIsMenuOpen( !isMenuOpen ) } }>{isMenuOpen ? "X" : " menu"}</div>
                <ul className={ "nav" + ( isMenuOpen ? "" : " hidden" ) }>
                    <li className="nav logo">
                        <img src="KAKAOARBO_logo.svg" alt="logo KAKAOARBO" />
                    </li>
                    <li className="nav" onClick={playIntro}>
                        Intro
                    </li>
                    <li className="nav">
                        <Link to="/">{ t( "menu.home" ) }</Link>
                    </li>
                    <li className="nav">
                        <Link to="/bean2bar">{ t( "menu.bean2bar" ) }</Link>
                    </li>
                    <li className="nav">
                        <Link to="/transport">{ t( "menu.transport" ) }</Link>
                    </li>
                    <li className="nav">
                        <Link to="/sourcing">{ t( "menu.sourcing" ) }</Link>
                    </li>
                    <li className="nav">
                        <Link to="/products">{ t( "menu.products" ) }</Link>
                    </li>
                    <li className="nav">
                        <Link to="/philosophy">{ t( "menu.philosophy" ) }</Link>
                    </li>
                    {/* <li className="nav">
                        <Link to="/shop">{ t( "menu.webshop" ) }</Link>
                    </li> */}
                    {/* <li className="nav">
                        <Link to="/contact">{ t( "menu.contact" ) }</Link>
                    </li> */}
                    <li className="nav">
                        <LangSwitcher/>
                    </li>
                </ul>
            </nav>
        </>
    )
}