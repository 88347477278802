/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 .\src\assets\3d\tree\scene.gltf -r .src/components/ 
*/

import React, { forwardRef, useImperativeHandle, useRef, useEffect, useMemo } from 'react'
import { useGLTF, Sampler } from '@react-three/drei'
/* import { useControls, folder } from 'leva' */
import { InstancedRigidBodies } from '@react-three/rapier'
import gsap from 'gsap'
import * as THREE from 'three'
import { MeshSurfaceSampler } from 'three/addons/math/MeshSurfaceSampler.js'

import scene from './tree.glb'


// export default forwardRef(Tree) at the bottom
function Tree(props, ref) {

    const { nodes, materials } = useGLTF(scene)

    // functions to be used by parent component
    useImperativeHandle(
        ref,
        () => { return {
            freePods() {freePods()},
            rotateTree() {rotateTree()}
        }}
    )

    const   posX = 0,
            posY = 0.2,
            posZ = 0,
            scale = 0.11,
            speed = 0.0,
            speedFactor = 1

    // LEVA CONTROLS
    /* const { _posX, _posY, _posZ, _scale, _speed, _speedFactor } = isDebug ? useControls(
    {
        'Tree Island': folder(
        {'Tree': folder(
            {
            _posX: {
                value: posX,
                min: -10,
                max: 10,
                step: .1
            },
            _posY: {
                value: posY,
                min: -10,
                max: 10,
                step: .1
            },
            _posZ: {
                value: posZ,
                min: -10,
                max: 10,
                step: .1
            },
            _scale: {
                value: scale,
                min: 0,
                max: 0.2,
                step: .01
            },
            _speed: {
                value: speed,
                min: 0,
                max: 1,
                step: .01
            },
            _speedFactor: {
                value: speedFactor,
                min: 1,
                max: 100,
                step: 0.1
            }
            },
            { collapsed: true }
        )},
        { collapsed: true }
            
        )
    }) : 0 */

    // PODS ANIMATION
    const podsRef = []
    const leavesRef = []
    const leafModulo = 1

    function freePods() {
        rigidPodsInstancesRef.current.forEach((api) => {
            api.setEnabledRotations(true, true, true, true)
            api.setEnabledTranslations(true, true, true, true)

            api.applyImpulse({ x: (Math.random() - 0.5 )*0.0001, y: 0, z: (Math.random() - 0.5 )*0.0001 })

            /* setTimeout(() => {
                api.setEnabledRotations(false, false, false, false)
                api.setEnabledTranslations(false, false, false, false)
            }, 5000) */

        })
        setTimeout(() =>{ 
            rigidLeavesInstancesRef.current.forEach((api) => {
                api.setEnabledRotations(true, true, true, true)
                api.setEnabledTranslations(true, true, true, true)

                api.applyImpulse({ x: (Math.random() - 0.5 )*0.004, y: Math.random() * 0.005, z: (Math.random() - 0.5 )*0.004 })
                /* api.applyTorqueImpulse({ x: Math.random() - 0.5, y: Math.random() - 0.5, z: Math.random() - 0.5 }) */
        

                /* setTimeout(() => {
                    api.setEnabledRotations(false, false, false, false)
                    api.setEnabledTranslations(false, false, false, false)
                }, 8000) */
            })
        }, 1000) //Delay
    }

    // GSAP ANIMATION
    const treeRef = useRef() 

    const   initPosition = [0, 0, 0],
            initRotation = [0, 0, 0]

    const tl = gsap.timeline()

    function rotateTree() {

    tl.to( treeRef.current.position,
        { y: -50, duration: 1.7, ease: 'expo.in' }, 2 )

    tl.to( treeRef.current.rotation,
        { y: -50, duration: 1.7, ease: 'expo.in' }, 2 )
    }


    // refs
    const smallBranchesRef = useRef()
    const tronkRef = useRef()
    const leavesInstancesRef = useRef()
    const podsInstancesRef = useRef()
    const rigidPodsInstancesRef = useRef()
    const rigidLeavesInstancesRef = useRef()
    // instances
    const podsCount = 40
    const leavesCount = 500
    
      // We can set the initial positions, and rotations, and scales, of
      // the instances by providing an array of InstancedRigidBodyProps
      // which is the same as RigidBodyProps, but with an additional "key" prop.
    
    const rigidPodsInstances = useMemo(() => {

        const instances         = []
        const surfaceGeometry   = nodes.bark_tronk.geometry
		const surfaceMaterial   = materials.bark
        const surface           = new THREE.Mesh( surfaceGeometry, surfaceMaterial )
        const _position         = new THREE.Vector3()
        let sampler             = new MeshSurfaceSampler( surface ).build()

        for (let i = 0; i < podsCount; i++) {
            const scale = 0.5 + Math.random()
            sampler.sample(_position)
            instances.push({
                key: i,
                scale: [ scale, scale, scale ],
                position: [ _position.x, _position.y, _position.z ],
                rotation: [ (Math.random() - 0.5) * 0.5, 0, (Math.random() - 0.5) * 0.5 ],
            });
        }
        
        return instances;
    }, [])
    
    const rigidLeavesInstances = useMemo(() => {

        const instances         = []
        const surfaceGeometry   = nodes.bark_small_branches.geometry
		const surfaceMaterial   = materials.bark
        const surface           = new THREE.Mesh( surfaceGeometry, surfaceMaterial )
        const _position         = new THREE.Vector3()
        let sampler             = new MeshSurfaceSampler( surface ).build()

        for (let i = 0; i < leavesCount; i++) {
            const scale = 0.5 + Math.random() * 0.7
            sampler.sample( _position )

            instances.push({
                key: i,
                scale: [scale, scale, scale ],
                position: [ _position.x, _position.y, _position.z ],
                rotation: [ (Math.random() - 0.5), Math.random() * Math.PI * 2,  (Math.random() - 0.5) ],
            });
        }
        
        return instances;
    }, [])

    // RETURN
    return (
        <>
            <group {...props} dispose={null}>
                <group scale={ scale } position={ [posX, posY, posZ] }>
                    <group ref={treeRef} rotation={initRotation} position={initPosition}>
                        <mesh ref={tronkRef} castShadow geometry={nodes.bark_tronk.geometry} material={materials.bark} />
                        <mesh ref={smallBranchesRef} castShadow geometry={nodes.bark_small_branches.geometry} material={materials.bark} />
                    </group>
                    
                    <InstancedRigidBodies
                        ref={rigidPodsInstancesRef}
                        instances={rigidPodsInstances}
                        gravityScale={0.3} 
                        restitution={0.2}
                        enabledTranslations={[false, false, false]}
                        enabledRotations={[false, false, false]}
                    >
                        <instancedMesh
                            castShadow
                            ref={podsInstancesRef}
                            args={[
                                nodes.pod.geometry, 
                                materials.pod, 
                                podsCount]}
                        />
                    
                    </InstancedRigidBodies>
                    
                    <Sampler
                        mesh={tronkRef}
                        instances={podsInstancesRef}
                        count={podsCount}
                    />
                    
                    <InstancedRigidBodies
                        ref={rigidLeavesInstancesRef}
                        instances={rigidLeavesInstances}
                        gravityScale={0.04} 
                        restitution={0}
                        friction={100}
                        enabledTranslations={[false, false, false]}
                        enabledRotations={[false, false, false]}
                    >
                        <instancedMesh 
                            castShadow
                            ref={leavesInstancesRef} 
                            args={[
                                nodes.leaf.geometry, 
                                materials.leaf, 
                                leavesCount]}
                        />
                    </InstancedRigidBodies>

                    <Sampler
                        mesh={smallBranchesRef}
                        instances={leavesInstancesRef}
                        count={leavesCount}
                        /* transform={({ position, normal, dummy: object }) => {
                            object.scale.setScalar(0.5 + Math.random() * 0.7)
                            object.position.copy(position)
                            object.lookAt(normal.add(position))
                            object.rotation.x += (Math.random() - 0.5) * 0.25
                            object.rotation.z += (Math.random() - 0.5)
                            object.updateMatrix()
                            return object
                        }} */
                    />
                    
                </group>
            </group>
        </>
    )
}

export default forwardRef(Tree)
useGLTF.preload(scene)

