import * as THREE from 'three'
import { useThree, useFrame } from '@react-three/fiber'

// Rig function : offset camera position according to pointer position
export default function Rig() {
    const { camera, pointer } = useThree()
    const vec = new THREE.Vector3()
    const amplificator = 3
    
    return useFrame(() => {
      camera.position.lerp(vec.set(pointer.x * amplificator, pointer.y * amplificator, camera.position.z), 0.05)
    })
}