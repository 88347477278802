import * as THREE from 'three'
import { extend, useFrame } from "@react-three/fiber"
import { Plane, shaderMaterial } from "@react-three/drei"
import { useControls, folder } from "leva"
import { useRef } from 'react'

/* import vertexShader from '../../assets/shaders/sea/vertexShader.glsl'
import fragmentShader from '../../assets/shaders/sea/fragmentShader.glsl' */

import useSeaStore from '../../stores/useSea.jsx'
import useDebugStore from '../../stores/useDebug.jsx'

export default function Sea(props) {

    const vertexShader = `uniform float uTime;
    uniform float uBigWavesElevation;
    uniform vec2 uBigWavesFrequency;
    uniform float uBigWavesSpeed;
    
    uniform float uSmallWavesElevation;
    uniform float uSmallWavesFrequency;
    uniform float uSmallWavesSpeed;
    uniform float uSmallIterations;
    
    varying float vElevation;
    
    //	Simplex 3D Noise 
    //	by Ian McEwan, Ashima Arts
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
    
    float snoise(vec3 v){ 
      const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
      const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
    
    // First corner
      vec3 i  = floor(v + dot(v, C.yyy) );
      vec3 x0 =   v - i + dot(i, C.xxx) ;
    
    // Other corners
      vec3 g = step(x0.yzx, x0.xyz);
      vec3 l = 1.0 - g;
      vec3 i1 = min( g.xyz, l.zxy );
      vec3 i2 = max( g.xyz, l.zxy );
    
      //  x0 = x0 - 0. + 0.0 * C 
      vec3 x1 = x0 - i1 + 1.0 * C.xxx;
      vec3 x2 = x0 - i2 + 2.0 * C.xxx;
      vec3 x3 = x0 - 1. + 3.0 * C.xxx;
    
    // Permutations
      i = mod(i, 289.0 ); 
      vec4 p = permute( permute( permute( 
                 i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
               + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
               + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
    
    // Gradients
    // ( N*N points uniformly over a square, mapped onto an octahedron.)
      float n_ = 1.0/7.0; // N=7
      vec3  ns = n_ * D.wyz - D.xzx;
    
      vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
    
      vec4 x_ = floor(j * ns.z);
      vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
    
      vec4 x = x_ *ns.x + ns.yyyy;
      vec4 y = y_ *ns.x + ns.yyyy;
      vec4 h = 1.0 - abs(x) - abs(y);
    
      vec4 b0 = vec4( x.xy, y.xy );
      vec4 b1 = vec4( x.zw, y.zw );
    
      vec4 s0 = floor(b0)*2.0 + 1.0;
      vec4 s1 = floor(b1)*2.0 + 1.0;
      vec4 sh = -step(h, vec4(0.0));
    
      vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
      vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
    
      vec3 p0 = vec3(a0.xy,h.x);
      vec3 p1 = vec3(a0.zw,h.y);
      vec3 p2 = vec3(a1.xy,h.z);
      vec3 p3 = vec3(a1.zw,h.w);
    
    //Normalise gradients
      vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
      p0 *= norm.x;
      p1 *= norm.y;
      p2 *= norm.z;
      p3 *= norm.w;
    
    // Mix final noise value
      vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
      m = m * m;
      return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                    dot(p2,x2), dot(p3,x3) ) );
    }
    ///////// END SIMPLEX 3D NOISE //////////////
    /////////////////////////////////////////////
    
    void main()
    {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    
        // Elevation
        float elevation = sin(modelPosition.x * uBigWavesFrequency.x + uTime * uBigWavesSpeed) *
                          sin(modelPosition.z * uBigWavesFrequency.y + uTime * uBigWavesSpeed) *
                          uBigWavesElevation;
    
        for(float i = 1.0; i <= uSmallIterations; i++)
        {
            elevation -= abs(snoise(vec3(modelPosition.xz * uSmallWavesFrequency * i, uTime * uSmallWavesSpeed)) * uSmallWavesElevation / i);
        }
        
        modelPosition.y += elevation;
    
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectedPosition = projectionMatrix * viewPosition;
        gl_Position = projectedPosition;
    
        vElevation = elevation;
    }`
    const fragmentShader = `uniform vec3 uDepthColor;
    uniform vec3 uSurfaceColor;
    uniform float uColorOffset;
    uniform float uColorMultiplier;
    
    varying float vElevation;
    
    void main()
    {
        float mixStrength = (vElevation + uColorOffset) * uColorMultiplier;
        vec3 color = mix(uDepthColor, uSurfaceColor, mixStrength);
        
        gl_FragColor = vec4(color, 1.0);
    }`

    const isDebug = useDebugStore((state) => state.isDebug)

    const uDepthColor = useSeaStore((state) => state.uDepthColor)
    const uSurfaceColor = useSeaStore((state) => state.uSurfaceColor)

    const   uColorOffset = 0.39,
            uColorMultiplier = 2.56,
            uBigWavesElevation = 0.09,
            uBigWavesFrequencyX = 0.24,
            uBigWavesFrequencyY = 0.17,
            uBigWavesSpeed = 0.68,
            uSmallWavesElevation = 0.14,
            uSmallWavesFrequency = 0.75,
            uSmallWavesSpeed = 0.17,
            uSmallIterations = 3

    /* const { _uDepthColor,
            _uSurfaceColor,
            _uColorOffset,
            _uColorMultiplier,
            _uBigWavesElevation,
            _uBigWavesFrequencyX,
            _uBigWavesFrequencyY,
            _uBigWavesSpeed,
            _uSmallWavesElevation,
            _uSmallWavesFrequency,
            _uSmallWavesSpeed,
            _uSmallIterations } = isDebug ? useControls(
        {
            'Sea': folder(
                {
                    _uDepthColor: uDepthColor,
                    _uSurfaceColor: uSurfaceColor,
                    _uColorOffset: {
                        value: uColorOffset,
                        min: 0,
                        max: 1,
                        step: 0.001
                    },
                    _uColorMultiplier: {
                        value: uColorMultiplier,
                        min: 0,
                        max: 10,
                        step: 0.001
                    },
                    _uBigWavesElevation: {
                        value: uBigWavesElevation,
                        min: 0,
                        max: 1,
                        step: 0.001
                    },
                    _uBigWavesFrequencyX: {
                        value: uBigWavesFrequencyX,
                        min: 0,
                        max: 0.5,
                        step: 0.001
                    },
                    _uBigWavesFrequencyY: {
                        value: uBigWavesFrequencyY,
                        min: 0,
                        max: 0.5,
                        step: 0.001
                    },
                    _uBigWavesSpeed: {
                        value: uBigWavesSpeed,
                        min: 0,
                        max: 4,
                        step: 0.001
                    },
                    _uSmallWavesElevation: {
                        value: uSmallWavesElevation,
                        min: 0,
                        max: 1,
                        step: 0.001
                    },
                    _uSmallWavesFrequency: {
                        value: uSmallWavesFrequency,
                        min: 0,
                        max: 30,
                        step: 0.001
                    },
                    _uSmallWavesSpeed: {
                        value: uSmallWavesSpeed,
                        min: 0,
                        max: 1,
                        step: 0.001
                    },
                    _uSmallIterations: {
                        value: uSmallIterations,
                        min: 0,
                        max: 5,
                        step: 1
                    },
                },
                { collapsed: true }
            )
        }
    ) : 0 */

    const MyMaterial = shaderMaterial(
        { 
            uDepthColor: new THREE.Color('#0070b4'),
            uSurfaceColor: new THREE.Color('#bfe5f2'),
            uColorOffset: 0.08,
            uColorMultiplier: 5,
            uTime: 0,
            uBigWavesElevation: 0.2,
            uBigWavesFrequency: new THREE.Vector2(4, 1.5),
            uBigWavesSpeed: 0.75,
            uSmallWavesElevation: 0.15,
            uSmallWavesFrequency: 3,
            uSmallWavesSpeed: 0.2,
            uSmallIterations: 4,
        }, // uniforms
        vertexShader, // glsl vextex shader
        fragmentShader // glsl fragment shader
    )
    // declaratively
    extend({ MyMaterial })

    const ref = useRef()

    useFrame(({ clock }) => {
        ref.current.uniforms.uTime.value = clock.getElapsedTime()
    })
    
    return (
        <Plane args={[100, 100, 400, 400]} rotation={[-Math.PI / 2, 0, 0]}>
            <myMaterial ref={ref}
                uDepthColor= { new THREE.Color( uDepthColor ) }
                uSurfaceColor= { new THREE.Color( uSurfaceColor ) }
                uColorOffset= { uColorOffset }
                uColorMultiplier= { uColorMultiplier }
                uTime= { 0 }
                uBigWavesElevation= { uBigWavesElevation }
                uBigWavesFrequency= { new THREE.Vector2( uBigWavesFrequencyX, uBigWavesFrequencyY ) }
                uBigWavesSpeed= { uBigWavesSpeed }
                uSmallWavesElevation= { uSmallWavesElevation }
                uSmallWavesFrequency= { uSmallWavesFrequency }
                uSmallWavesSpeed= { uSmallWavesSpeed }
                uSmallIterations= { uSmallIterations }
            />
        </Plane>
    )
}