import { Outlet } from "react-router-dom"
import { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { changeLanguage } from "i18next"

import useIntroStore from '../stores/useIntro.jsx'
import useMainStore from '../stores/useMain.jsx'

import App3D from "../components/canvas3D/App3D.jsx"
import Header from "../components/html/Header.jsx"
import Footer from "../components/html/Footer.jsx"


export default function Layout() {

    const [t, i18n] = useTranslation("global")

    const   isMain  = useMainStore((state) => state.isMain),
            isIntro = useIntroStore((state) => state.isIntro)

    
    useEffect(() => {
        // Access session storage
        const lang = sessionStorage.getItem("lang")

        if (lang !== null) {
            changeLanguage(lang)
        }
        
    }, []); //No dependency to trigger in each page load

    return (
    <>
        { isIntro && ( <Suspense fallback={<h2 id="loading">{ t( "loading" ) }</h2>}><App3D /></Suspense> ) }
        
        { isMain && 
            (
                <div className="page-wrapper">
                    <div className="header"><Header /></div>
                    <div className="outlet"><Outlet /></div>
                    <div className="footer"><Footer /></div>                    
                </div>
            )
        }
    </>
    )
}
