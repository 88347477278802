import { create } from 'zustand'
    
const screenStore = (set) => ({

    // STATES
    orientation: 'paysage',
    device: 'pc',

    // SETTERS
    setOrientation: () => {
        set((state) => {
            if (window.innerWidth / window.innerHeight > 1.6) {
                return { orientation: 'paysage'}
            } else {
                return { orientation: 'portrait'}
            }           
        })
    },
    setDevice: () => {
        set((state) => {
            if (window.innerWidth  < 720) {
                return { device: 'mobile'}
            } else {
                return { device: 'pc'}
            }           
        })
    },

    // EXAMPLES
/*     bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }),
    setBears: (value) => set({ bears: value }), */

    
})

const useScreenStore = create(screenStore)

export default useScreenStore