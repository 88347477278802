import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { useGSAP } from "@gsap/react"

import './css/interface.css'

import useIntroStore from '../../stores/useIntro.jsx'
import useMainStore from '../../stores/useMain.jsx'
import { log } from 'three/examples/jsm/nodes/Nodes.js'

import { useTranslation } from 'react-i18next'
import LangSwitcher from './langSwitcher.jsx'


// EXPORT DEFAULT FUNCTION
export default function Interface() {
    const [t, i18n] = useTranslation("intro")

    const   arrow = useRef(),
            scrollDown = useRef()

    const   isReadyToScroll     = useIntroStore((state) => state.isReadyToScroll),
            isIntro             = useIntroStore((state) => state.isIntro),
            incrementIntroState = useIntroStore((state) => state.incrementIntroState),
            unsetIntro          = useIntroStore((state) => state.unsetIntro)
        
    const   setMain             = useMainStore((state) => state.setMain)

    const skipIntro = () => {
        unsetIntro()
        setMain()
    }

    useGSAP(() => {
        if (arrow.current) {
            gsap.to(arrow.current, {
                y: 5,
                duration: 1,
                repeat: -1
            })
        }
    })

    // RETURN
    return (
        <div className="interface">
            { // INTRO
                isIntro &&
                (
                    <>
                        
                        <div id='menu_intro'>
                            <div className='pointer' id='skip_intro' onClick={skipIntro}>{t("skip")}</div>
                            <div className='pointer' ref={scrollDown} id="svg_scroll_down" style={{display: isReadyToScroll ? 'unset' : 'none'}}>
                                <svg onClick={incrementIntroState} width="398px" height="546px" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                    <path id="svg_scroll_down_border" d="M5 15C5 16.8565 5.73754 18.6371 7.05029 19.9498C8.36305 21.2626 10.1435 21.9999 12 21.9999C13.8565 21.9999 15.637 21.2626 16.9498 19.9498C18.2625 18.6371 19 16.8565 19 15V9C19 7.14348 18.2625 5.36305 16.9498 4.05029C15.637 2.73754 13.8565 2 12 2C10.1435 2 8.36305 2.73754 7.05029 4.05029C5.73754 5.36305 5 7.14348 5 9V15Z"/>
                                    <g ref={arrow} id="svg_scroll_down_arrow">
                                        <path id="svg_scroll_down_arrow_1" d="M12 6V14"/>
                                        <path id="svg_scroll_down_arrow_2" d="M15 11L12 14L9 11"/>
                                    </g>
                                </svg>
                            </div>
                            <div className='pointer'><LangSwitcher/></div>
                        </div>
                    </>
                )
            }
            {/* <div>
                {<>   
                    <p>{cameraPosX}</p>
                    <p>{cameraPosY}</p>
                    <p>{cameraPosZ}</p>
                    <p>{cameraLookAtX}</p>
                    <p>{cameraLookAtY}</p>
                    <p>{cameraLookAtZ}</p>
                </>}
            </div> */}
        </div>
    )
}