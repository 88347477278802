import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const introStore = (set) => ({

    // STATES
    introState: 0,          // on change: triggers animations
    cameraMovementState: 0, // on change: triggers camera movements
    isIntro: true,          // true: set intro components
    isReadyToScroll: false, // true: shows the clickable arrow and let scroll start next animation
    introInterval: 5,       // seconds before isReadyToScoll becomes true (after animation starts)

    timer: null,            // timer used for the setTimeOut in updateIsReadyToScroll setter

    // SETTERS
    incrementIntroState: () => {

        set((state) => {

            const delay = [2000, 0, 0, 0, 0, 0, 0] // delay before starting camera movement for each state
            
            if (state.isReadyToScroll) {// min 5sec. between 2 animations
                setTimeout(() => {

                    state.updateIsReadyToScroll()

                    set(() => {
                        return { cameraMovementState: state.cameraMovementState + 1 }
                    })
                    
                }, delay[state.introState]);

                state.updateIsReadyToScroll()

                return { introState: state.introState + 1 }
            }
            else { return {} } // if less than 5sec. do nothing
        })
    },

    updateIsReadyToScroll: () => {

        set((state) => { 

            clearTimeout(state.timer)

            return { 
                isReadyToScroll: false,
                timer:  setTimeout(() => {
                            set(() => { return { isReadyToScroll: true } })
                        }, state.introInterval * 1000)
            }
        })
    },

    unsetIntro: () => {
        set(() => { return { 
            cameraMovementState: 6,
            isIntro: false}
        })
    },

    // EXAMPLES
/*  bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }),
    setBears: (value) => set({ bears: value }), */
})

const useIntroStore = create( 
    persist(
        introStore,
        {
            name: 'introStore-storage', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        },
    )
)

export default useIntroStore