/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 .\src\assets\3d\viking_boat\scene.gltf -r .src/components/ 
*/

import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useGLTF, Decal, Text, RenderTexture, PerspectiveCamera, MeshDistortMaterial } from '@react-three/drei'
import gsap from 'gsap'
/* import Tiller from './Tiller.jsx' */

import scene from './boat.glb'
import { useTranslation } from 'react-i18next'



// export default forwardRef(Boat) at the bottom
function Boat(props, ref) {

  const [t, i18n] = useTranslation("intro")

  const { nodes, materials } = useGLTF(scene)
  const textArray =
  [
    t("text4.line1"), // 'A long journey,'
    t("text4.line2"), // 'onboard a SAILBOAT,'
    t("text4.line3"), // 'with the help from'
    t("text4.line4"), // 'the winds, the streams'
    t("text4.line5"), // 'and the power of the crew,'
    t("text4.line6"), // 'brings the cacao beans'
    t("text4.line7"), // 'from origin to me.'
  ]

  // functions to be used by parent component
  useImperativeHandle(
    ref,
    () => { return {
      boatIn() {boatIn()},
      boatOut() {boatOut()},
      /* rotateTree() {rotateTree()} */
    }},
    []
  )

  // GSAP ANIMATION
  const boatRef = useRef() 
  const boatRef2 = useRef() 
  
  const initPosition = [20,-0.3,2],
        initRotation = [0,Math.PI/2,0]
  
  const tl1 = gsap.timeline()
  
  function boatIn() {

    tl1.to( boatRef.current.position,
      { x: 0, duration: 5, ease: 'ease.out' }, 0 )
    tl1.to( boatRef.current.position,
      { z: 0, duration: 5, ease: 'ease.out' }, 0 )

  }

  const tl2 = gsap.timeline()

  function boatOut() {
    tl2.to( boatRef2.current.rotation,
      { y: 2 + Math.PI, duration: 2, ease: 'ease.inOut' }, 0 )
      tl2.to( boatRef.current.position,
        { x: -90, duration: 100, ease: 'ease.in' }, 2 )
      tl2.to( boatRef.current.position,
        { z: 30, duration: 100, ease: 'ease.in' }, 2 )

  }

  //RETURN
  return (
    <group 
      ref={boatRef}
      scale={ 1 }
      position={ [-15, 0, 5] }
      rotation={ [0, 0, 0] }
    >
      <group {...props} dispose={null}
        ref={boatRef2}
        scale={ 0.01 }
        position={ [-5, -0.3, 0] }
        rotation={ [0, 2, 0] }
      >
        <mesh geometry={nodes.Sail_Full_Sail_Full_0.geometry} material={materials.Sail_Full} position={[4.14, 420.924, 12.833]} rotation={[0, Math.PI / 6, 0]}>
          <Decal 
            // debug // Makes "bounding box" of the decal visible
            position={[0, -150, 50]} // Position of the decal
            rotation={[0, 0, 0]} // Rotation of the decal (can be a vector or a degree in radians)
            scale={300} // Scale of the decal
          >
            <meshStandardMaterial roughness={0.6} transparent polygonOffset polygonOffsetFactor={-10}>
              <RenderTexture attach="map" anisotropy={16}>
              <PerspectiveCamera makeDefault manual aspect={1} position={[0, 0, 10]} />
              <ambientLight intensity={1} />
                {textArray.map((line, index) => {
                  return (
                    <Text
                        position={[0, 3 - index * 1.2, 0]}
                        rotation={[0,0,0]}
                        scale={0.6}
                        color={'#000000'}
                        key={index}
                    >
                        {line}
                        <meshStandardMaterial/>
                    </Text>
                  )
                })}
              </RenderTexture>
            </meshStandardMaterial>

            
          </Decal>
        </mesh>
        
        <mesh geometry={nodes.Sail_Ropes_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[4.14, 420.924, 12.833]} rotation={[0, Math.PI / 6, 0]} />
        <mesh geometry={nodes.Stay_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[0, 92.012, 334.647]} rotation={[-0.729, 0, 0]} />
        <mesh geometry={nodes.Stems_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} />
        <mesh geometry={nodes.Yard_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[0, 425, 6.355]} rotation={[0, Math.PI / 6, 0]} />
        <mesh geometry={nodes.Frames_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} />
        <mesh geometry={nodes.Keel_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} />
        <mesh geometry={nodes.Mast_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[0, 18.506, 6.355]} rotation={[0, 1.484, Math.PI / 2]} />
        <mesh geometry={nodes.Planking_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} />

        {/* <mesh geometry={nodes.Yard_Rope_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[-6.632, 38.041, -73.104]} /> */}
        {/* <mesh geometry={nodes.Yard_Rope_Raised_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[6.189, 95.831, 6.088]} /> */}
        {/* <mesh geometry={nodes.Rigging_Pin_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[-73.647, 62.374, 83.811]} /> */}
        {/* <mesh geometry={nodes.Rigging_Pin001_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[72.429, 62.374, -151.984]} rotation={[-Math.PI, 0, -Math.PI]} /> */}
        {/* <mesh geometry={nodes.Rigging_Pin002_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[-72.66, 62.374, -151.984]} /> */}
        {/* <mesh geometry={nodes.Rudder_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[-30.279, 68.369, -325.946]} rotation={[0.37, 0.079, 0.257]} /> */}
        {/* <mesh geometry={nodes.Rudder_Frame_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} /> */}
        {/* <mesh geometry={nodes.Shrouds_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} /> */}
        {/* <mesh geometry={nodes.Stanchions_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} /> */}
        {/* <mesh geometry={nodes.Stringer_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} /> */}
        {/* <mesh geometry={nodes.Thole_Straps_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} /> */}
        {/* <mesh geometry={nodes.Tiller_Ship_Oak_Aged_0.geometry} material={materials.Ship_Oak_Aged} position={[-2.408, 105.155, -311.127]} rotation={[0.28, 0, 0]} /> */}
      </group>
      {/* <Tiller /> */}
    </group>
    
  )
}

export default forwardRef(Boat)
useGLTF.preload(scene)
