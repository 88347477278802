import { create } from 'zustand'
    
const seaStore = (set) => ({

    // UNIFORMS COLORS
    uDepthColor: '#0070b4',
    uSurfaceColor: '#bfe5f2',

    // SETTERS
    setDepthColor: (value) => set({ uDepthColor: value }),
    setSurfaceColor: (value) => set({ uSurfaceColor: value }),

    // EXAMPLES
/*     bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }),
    setBears: (value) => set({ bears: value }), */

    
})

const useSeaStore = create(seaStore)

export default useSeaStore