import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


const mainStore = (set) => ({

    // STATES
    mainState: 0,          // on change: triggers animations
    cameraMovementState: 6, // on change: triggers camera movements
    isMain: false,          // true: set intro components
    isReadyToScroll: false, // true: shows the clickable arrow and let scroll start next animation
    introInterval: 5,       // seconds before isReadyToScoll becomes true (after animation starts)

    timer: null,            // timer used for the setTimeOut in updateIsReadyToScroll setter

    // SETTERS
    setMain: () => set({ isMain: true }),
    

    // EXAMPLES
/*  bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }),
    setBears: (value) => set({ bears: value }), */
})

const useMainStore = create(
    persist(
        mainStore,
        {
            name: 'mainStore-storage', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        },
    )
)

export default useMainStore