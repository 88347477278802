import { useRef, useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useControls, folder } from 'leva'
import { CameraControls } from '@react-three/drei'

import useCameraStore from '../../stores/useCamera.jsx'
import useDebugStore from '../../stores/useDebug.jsx'
import useScreenStore from '../../stores/useScreen.jsx'
import useIntroStore from '../../stores/useIntro.jsx'
import useMainStore from '../../stores/useMain.jsx'

export default function MyCamera() {
    
    const   orientation           = useScreenStore((state) => state.orientation),
            setOrientation        = useScreenStore((state) => state.setOrientation),
            setDevice             = useScreenStore((state) => state.setDevice)

    const   isDebug = useDebugStore((state) => state.isDebug)

    
    const   cameraTargetX         = useCameraStore((state) => state.cameraLookAtX),
            cameraTargetY         = useCameraStore((state) => state.cameraLookAtY),
            cameraTargetZ         = useCameraStore((state) => state.cameraLookAtZ),
            cameraPositionX       = useCameraStore((state) => state.cameraPosX),
            cameraPositionY       = useCameraStore((state) => state.cameraPosY),
            cameraPositionZ       = useCameraStore((state) => state.cameraPosZ)
    
    const   setCameraPosX         = useCameraStore((state) => state.setCameraPosX),
            setCameraPosY         = useCameraStore((state) => state.setCameraPosY),
            setCameraPosZ         = useCameraStore((state) => state.setCameraPosZ),
            setCameraLookAtX      = useCameraStore((state) => state.setCameraLookAtX),
            setCameraLookAtY      = useCameraStore((state) => state.setCameraLookAtY),
            setCameraLookAtZ      = useCameraStore((state) => state.setCameraLookAtZ)
    
    const   setCamera             = useCameraStore((state) => state.setCamera)
    

    const   introState               = useIntroStore((state) => state.introState)
    const   isIntro                  = useIntroStore((state) => state.isIntro)
    const   cameraMovementStateIntro = useIntroStore((state) => state.cameraMovementState)
    const   cameraMovementStateMain  = useMainStore((state) => state.cameraMovementState)

    const   updateIsReadyToScroll    = useIntroStore((state) => state.updateIsReadyToScroll)
    
    const   mainState                = useMainStore((state) => state.mainState)

    const   { camera } = useThree()

    /* const   {
        cameraMouseControl,
        cameraPosX, cameraPosY, cameraPosZ,
        cameraLookAtX, cameraLookAtY, cameraLookAtZ
    } = isDebug 
        ? useControls(
        {'Camera': folder(
            {
                cameraMouseControl: useCameraStore((state) => state.cameraMouseControl),
                cameraPosX: {
                    value: cameraPositionX,
                    min: -10,
                    max: 50,
                    step: .1,
                    onChange: (value) => { setCameraPosX(value) },
                },
                cameraPosY: {
                    value: cameraPositionY,
                    min: -10,
                    max: 50,
                    step: .1,
                    onChange: (value) => { setCameraPosY(value) },
                },
                cameraPosZ: {
                    value: cameraPositionZ,
                    min: -10,
                    max: 50,
                    step: .1,
                    onChange: (value) => { setCameraPosZ(value) },
                },
                cameraLookAtX: {
                    value: cameraTargetX,
                    min: -10,
                    max: 20,
                    step: .1,
                    onChange: (value) => { setCameraLookAtX(value) },
                },
                cameraLookAtY: {
                    value: cameraTargetY,
                    min: -10,
                    max: 20,
                    step: .1,
                    onChange: (value) => { setCameraLookAtY(value) },
                },
                cameraLookAtZ: {
                    value: cameraTargetZ,
                    min: -10,
                    max: 20,
                    step: .1,
                    onChange: (value) => { setCameraLookAtZ(value) },
                }
            }, { collapsed: true }
        )}
    ) 
        : 0 */

    const cameraControlsRef = useRef()

    const cameraData = {
        'paysage':
        [
            { posX:  0.6, posY: 1.3, posZ:  5, lookAtX:  0.6, lookAtY: 1.5, lookAtZ: 0   }, // 0 INTRO
            { posX:  0  , posY: 2.2, posZ: -5, lookAtX:  0  , lookAtY: 1.5, lookAtZ: 3.2 }, // 1
            { posX:  0.6, posY: 1.3, posZ:  5, lookAtX:  0.6, lookAtY: 1.5, lookAtZ: 0   }, // 2
            { posX:  0  , posY: 2.2, posZ: -5, lookAtX:  0  , lookAtY: 1.5, lookAtZ: 3.2 }, // 3
            { posX:  5.7, posY: 1.1, posZ: 10, lookAtX:  2.5, lookAtY: 1.1, lookAtZ: 5.7 }, // 4
            { posX: 11  , posY: 1.1, posZ: 15, lookAtX: 13  , lookAtY: 6  , lookAtZ: 20  }, // 5
            { posX:  0  , posY:   0, posZ:  5, lookAtX:  0  , lookAtY: 0  , lookAtZ:  0  }  // 6 MAIN
        ],
        'portrait':
        [
            { posX:  0, posY: 2  , posZ:  5  , lookAtX:  0  , lookAtY: 2.6, lookAtZ:  0 }, // 0 INTRO
            { posX:  0, posY: 2  , posZ: -5.5, lookAtX:  0  , lookAtY: 3  , lookAtZ:  0 }, // 1
            { posX:  0, posY: 2  , posZ:  5  , lookAtX:  0  , lookAtY: 2.5, lookAtZ:  0 }, // 2
            { posX: -3, posY: 2.2, posZ: -4.5, lookAtX: -3.8, lookAtY: 2.2, lookAtZ:  0 }, // 3
            { posX:  4, posY: 2.5, posZ:  7.5, lookAtX:  2.5, lookAtY: 2.5, lookAtZ:  5 }, // 4
            { posX: 11, posY: 1.1, posZ: 15  , lookAtX: 13  , lookAtY: 6  , lookAtZ: 20 }, // 5
            { posX:  0, posY: 0  , posZ:  5  , lookAtX:  0  , lookAtY: 0  , lookAtZ:  0 }  // 6 MAIN
        ]
    }
    
    useEffect(() => {
        isIntro && updateIsReadyToScroll()
        setCamera( cameraData[orientation][isIntro ? introState : mainState] )
    },[cameraMovementStateIntro, cameraMovementStateMain, orientation])


    // SET CAMERA POSITION AND TRANSITION
    useEffect(() => {
        if (cameraControlsRef.current) {
                cameraControlsRef.current.smoothTime = 1.1
                cameraControlsRef.current.restThreshold = 10
                cameraControlsRef.current?.setLookAt(
                    cameraPositionX, cameraPositionY, cameraPositionZ,
                    cameraTargetX, cameraTargetY, cameraTargetZ,
                    true
                )
        }
    }, [cameraPositionX, cameraPositionY, cameraPositionZ, cameraTargetX, cameraTargetY, cameraTargetZ])

    useEffect(() => {
        window.addEventListener('resize', () => { setDevice() ; setOrientation()})
        setDevice()
        setOrientation()

        return () => {
            window.removeEventListener('resize', () => { setDevice() ; setOrientation()})
        }
    }, [])

    // DISABLE OR ENABLE USER CONTROL OF CAMERA
    useEffect(() => {
        
        const   mouseBtn = cameraControlsRef.current?.mouseButtons,
                touches  = cameraControlsRef.current?.touches
            
        touches.one = 0
        touches.two = 0
        touches.three = 0

        if ( isDebug ) {
            mouseBtn.left = 1 // rotate
            mouseBtn.right = 2 // truck
            mouseBtn.middle = 16 // dolly
            mouseBtn.wheel = 8 // zoom
        } else {
            mouseBtn.left = 0
            mouseBtn.right = 0
            mouseBtn.middle = 0
            mouseBtn.wheel = 0
        }
    }, [])

    return (
        <CameraControls
            ref={cameraControlsRef}
            makeDefault
        />
    )
}