import React from "react"
import { useGLTF } from "@react-three/drei"
import { animated, useSpringValue } from "@react-spring/three"
/* import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper' */

import TorusSpinningLight from "./TorusSpinningLight.jsx"

import logo from './kakaoarbo-logo.glb'


export default function Logo(props) {

  const { nodes, materials } = useGLTF(logo)

  // RETURN
  return (
    <>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          geometry={nodes.Tree.geometry}
          /* material={materials["Material.tree"]} */
        >
          <meshStandardMaterial color={'#015201'} roughness={0.2}/>
        </mesh>
        <mesh
          receiveShadow
          geometry={nodes.greenstar.geometry}
          /* material={materials["Material.star"]} */
        >
          <meshStandardMaterial color={'#00AA00'} roughness={0.2}/>
        </mesh>

        <Letters />
      </group>
      <TorusSpinningLight/>
    </>
  );
}

useGLTF.preload(logo)

// ANIMATED LETTERS OF "KAKAOARBO"
function Letters() {
  
  const { nodes, materials } = useGLTF(logo);

  const whiteLetter = materials["Material.letter.white"],
        redLetter = materials["Material.letter.red"]

  const initLetterScale = 1,
        initLetterPosition = [0, 0.05, 0],
        initLetterRotation = [0, 0, 0]

  const configPosition = {tension: 250, friction: 12}
  const configRotation = {}

  const lettersData = 
  [
    {
      "geometry": nodes.K_1.geometry,
      "material": whiteLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#bbbbbb'
    },
    {
      "geometry": nodes.A_2.geometry,
      "material": whiteLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#bbbbbb'
    },
    {
      "geometry": nodes.K_3.geometry,
      "material": whiteLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#bbbbbb'
    },
    {
      "geometry": nodes.A_4.geometry,
      "material": whiteLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#bbbbbb'
    },
    {
      "geometry": nodes.O_5.geometry,
      "material": whiteLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#bbbbbb'
    },
    {
      "geometry": nodes.A_6.geometry,
      "material": redLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#9C1F13'
    },
    {
      "geometry": nodes.R_7.geometry,
      "material": redLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#9C1F13'
    },
    {
      "geometry": nodes.B_8.geometry,
      "material": redLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#9C1F13'
    },
    {
      "geometry": nodes.O_9.geometry,
      "material": redLetter,
      "scale": useSpringValue( initLetterScale ),
      "position": useSpringValue( initLetterPosition ,{ config: configPosition } ),
      "rotation": useSpringValue( initLetterRotation , { config: configRotation } ),
      "color": '#9C1F13'
    }
  ]

  function handlePointerEnter(index) {
    lettersData[index].scale.start(1.05 + getRandom( 0.1 ))
    lettersData[index].position.start( 
      [
        0.01 + getRandom( 0.03 ),
        0.1 + getRandom( 0.4 ),
        0.05 + getRandom( 0.1 )
      ]
    )
    lettersData[index].rotation.start( 
      [
        -0.25 + getRandom( 0.5 ),
        -0.25 + getRandom( 0.5 ),
        -0.25 + getRandom( 0.5 )
      ]
    )
  }
  
  function handlePointerLeave(index) {
    lettersData[index].scale.start( initLetterScale )
    lettersData[index].position.start( initLetterPosition )
    lettersData[index].rotation.start( initLetterRotation )
  }

  return (
    <>
      {lettersData.map( (letterData, i) =>
        (
        <animated.mesh
          key={i}
          ref={letterData.ref}
          castShadow
          geometry={letterData.geometry}
          /* material={letterData.material} */
          scale={letterData.scale}
          position={letterData.position}
          rotation={letterData.rotation}
          onPointerEnter={ () => { handlePointerEnter(i) } }
          onPointerLeave={ () => { handlePointerLeave(i) } }
        >
          <meshStandardMaterial color={letterData.color} roughness={0.1}/>
        </animated.mesh>
        )
      )}

    </>
  )
}

function getRandom(max) {
  const randomNr = Math.random()
  const result = randomNr * max
  return result
}