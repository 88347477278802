/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 .\src\assets\3d\cocoa\scene.gltf -r .src/components/ 
Author: Jasmin Daniel (https://sketchfab.com/jasmin.daniel)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cocoa-c60b02c8a7f34967974b3feb46d8ad87
Title: Cocoa
*/

import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { useGLTF } from '@react-three/drei'
import gsap from 'gsap'

import scene from './cocoa.glb'

// export default forwardRef(Cocoa) at the bottom
function Cocoa(props, ref) {
  const { nodes, materials } = useGLTF(scene)

  // functions to be used by parent component
  useImperativeHandle(
    ref,
    () => { return {
      rotatePod() {rotatePod()}
    }},
    []
  )

  // GSAP ANIMATION
  const podRef = useRef() 
  const podRef2 = useRef() 
  
  const initPosition = [1,-4,-1],
        initRotation = [0.8,-1,0.5]
  
  const tl = gsap.timeline();
  
  function rotatePod() {

    tl.to( podRef.current.position,
      { y: 0.5, duration: 2, ease: 'expo.out' }, 2.5 )

    tl.to( podRef.current.rotation,
      { y: -0.6-Math.PI*4, duration: 2, ease: 'expo.out' }, 2.5 )
    
  }

  // RETURN
  return (
    <group ref={podRef2}
    scale={1}
    position={[0,0,0]}
    rotation={[0,0,0]}>
        <group {...props} ref={podRef} dispose={null}
          scale={0.14}
          position={[0,-4,0]}
          rotation={[0,-0.6,0]}
        >
          <mesh castShadow geometry={nodes['Object004_02_-_Default_0'].geometry} material={materials['02_-_Default']} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

export default forwardRef(Cocoa)
useGLTF.preload(scene)
