import { useEffect, useState } from "react"

export default function Footer() {

    const [telLink, setTelLink] = useState("")
    const [tel, setTel] = useState("...loading")
    const [emailLink, setEmailLink] = useState("")
    const [email, setEmail] = useState("...loading")

    useEffect(() => {
        const timer = setTimeout(() => {
            setTelLink(atob("dGVsOiszMzc0NTA3MjY5NA=="))
            setTel(atob("MDc0NTA3MjY5NA=="))
            setEmailLink(atob("bWFpbHRvOnRoZW9Aa2FrYW9hcmJvLm5ldA=="))
            setEmail(atob("dGhlb0BrYWthb2FyYm8ubmV0"))
        }, 2000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <h3>KAKAOARBO<br/>Artisan chocolatier bean-to-bar</h3>
            <p className="footer">09200 Montjoie-en-Couserans - <a className="footer" href={telLink}>{tel}</a> - <a className="footer" href={emailLink}>{email}</a></p>
        </>
    )
}