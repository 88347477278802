import React, { useLayoutEffect, useState } from "react"
import { Environment, Torus } from "@react-three/drei"
import { useControls, folder } from "leva"
import gsap from "gsap"
import { useGSAP } from "@gsap/react"

import useDebugStore from '../../stores/useDebug.jsx'



export default function TorusSpinningLight(
    { //props default values
        radius          = 3.5,
        tube            = 0.15,
        radialSegments  = 8,
        tubularSegments = 32,
        arc             = Math.PI*2,
        posX            = 0,
        posY            = 0,
        posZ            = 0,
        rotX            = 0,
        rotY            = 0,
        rotZ            = 0,
        helperColor     = '#ffffff',
        intensity       = 5,
        slowFactor      = 2.5,
        helper          = false
    })
{

    const isDebug = useDebugStore((state) => state.isDebug)
    
    // LEVA CONTROLS
    /* const {
        _radius, _tube, _radialSegments, _tubularSegments, _arc,
        _posX, _posY, _posZ,
        _rotX, _rotY, _rotZ,
        _intensity, _helperColor
    } = isDebug ? useControls({
        'Torus Spinning Light': folder(
            {
                _radius: {
                    value: radius,
                    min: 0.1,
                    max: 50,
                },
                _tube: {
                    value: tube,
                    min: 0.1,
                    max: 5,
                },
                _radialSegments: {
                    value: radialSegments,
                    min: 1,
                    max: 32,
                },
                _tubularSegments: {
                    value: tubularSegments,
                    min: 1,
                    max: 64,
                },
                _arc: {
                    value: arc,
                    min: 0,
                    max: Math.PI*2,
                },
                _posX: {
                    value: posX,
                    min: -30,
                    max: 30,
                },
                _posY: {
                    value: posY,
                    min: -30,
                    max: 30,
                },
                _posZ: {
                    value: posZ,
                    min: -30,
                    max: 30,
                },
                _rotX: {
                    value: rotX,
                    min: 0,
                    max: Math.PI*2,
                },
                _rotY: {
                    value: rotY,
                    min: 0,
                    max: Math.PI*2,
                },
                _rotZ: {
                    value: rotZ,
                    min: 0,
                    max: Math.PI*2,
                },
                _intensity: {
                    value: intensity,
                    min: 0,
                    max: 50,
                },
                _helperColor : helperColor,
            },
            {collapsed: true}
        ),
    }) : 0 */

    // GSAP ANIMATION
    const [rotationX, setRotationX] = useState(0)
    const [rotationY, setRotationY] = useState(0)

    const { contextSafe } = useGSAP()

    const animationLight = contextSafe(() => {
        const proxy1 = {rotationX: rotationX};
        gsap.to(proxy1, {
        rotationX: Math.PI*2,
            ease: "none",
            duration: 3*slowFactor,
            repeat: -1,
            onUpdate: () => setRotationX(proxy1.rotationX) // <-- update the real thing using React plumbing
        })
        const proxy2 = {rotationY: rotationY};
        gsap.to(proxy2, {
        rotationY: Math.PI*2,
            ease: "none",
            duration: 5*slowFactor,
            repeat: -1,
            onUpdate: () => {setRotationY(proxy2.rotationY)} // <-- update the real thing using React plumbing
        })
    })

    useLayoutEffect(() => {
    animationLight()
    },[])

    // RETURN
    return (
        <>
            <Environment>
            <Torus
                args={ [radius, tube, radialSegments, tubularSegments, arc] }
                position={ [posX, posY, posZ] }
                rotation={ [rotationX, rotationY, rotZ] }
            >
                <meshStandardMaterial emissive={ [1, 1, 1] } emissiveIntensity={ intensity } color={ helperColor }/>
            </Torus>
    
            </Environment>

            { helper &&
            
                <Torus
                    args={ [radius, tube, radialSegments, tubularSegments, arc] }
                    position={ [posX, posY, posZ] }
                    rotation={ [rotationX, rotationY, rotZ] }
                >
                    <meshStandardMaterial emissive={ [1, 1, 1] } emissiveIntensity={ intensity } color={ helperColor }/>
                </Torus>
            }
        </>
    )
}