import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import App from './App.jsx'

import reportWebVitals from './reportWebVitals'

import global_en from "./translations/en/global.json"
import global_fr from "./translations/fr/global.json"
import intro_en from "./translations/en/intro.json"
import intro_fr from "./translations/fr/intro.json"
import home_en from "./translations/en/home.json"
import home_fr from "./translations/fr/home.json"
import bean2bar_en from "./translations/en/bean2bar.json"
import bean2bar_fr from "./translations/fr/bean2bar.json"
import transport_en from "./translations/en/transport.json"
import transport_fr from "./translations/fr/transport.json"
import sourcing_en from "./translations/en/sourcing.json"
import sourcing_fr from "./translations/fr/sourcing.json"
import philosophy_en from "./translations/en/philosophy.json"
import philosophy_fr from "./translations/fr/philosophy.json"
import products_en from "./translations/en/products.json"
import products_fr from "./translations/fr/products.json"
import shop_en from "./translations/en/shop.json"
import shop_fr from "./translations/fr/shop.json"
import contact_en from "./translations/en/contact.json"
import contact_fr from "./translations/fr/contact.json"
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'

i18next.init({
  interpolation: { escapeValue: true },
  lng: "fr",
  fallbackLng: "en",
  debug: true,
  resources: {
    en: {
      global: global_en,
      intro: intro_en,
      home: home_en,
      bean2bar: bean2bar_en,
      transport: transport_en,
      sourcing: sourcing_en,
      products: products_en,
      philosophy: philosophy_en,
      shop: shop_en,
      contact: contact_en,
    },
    fr: {
      global: global_fr,
      intro: intro_fr,
      home: home_fr,
      bean2bar: bean2bar_fr,
      transport: transport_fr,
      sourcing: sourcing_fr,
      products: products_fr,
      philosophy: philosophy_fr,
      shop: shop_fr,
      contact: contact_fr,
    },

  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
