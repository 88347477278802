import { BrowserRouter, Routes, Route } from "react-router-dom"

import Contact      from "./pages/Contact.jsx"
import Layout       from "./pages/Layout.jsx"
import NoPage       from "./pages/NoPage.jsx"
import Shop         from "./pages/shop/Shop.jsx"
import Checkout     from "./pages/shop/Checkout.jsx"
import TemplateStd  from "./components/html/templateStd.jsx"

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"           element={<Layout />}>
          <Route index              element={<TemplateStd page="home" />} />
          {/* <Route path="shop"        element={<Shop />} /> */}
          {/* <Route path="checkout"    element={<Checkout />} /> */}
          <Route path="bean2bar"    element={<TemplateStd page="bean2bar" />} />
          <Route path="transport"   element={<TemplateStd page="transport" />} />
          <Route path="sourcing"    element={<TemplateStd page="sourcing" />} />
          <Route path="products"    element={<TemplateStd page="products" />} />
          <Route path="philosophy"  element={<TemplateStd page="philosophy" />} />
          {/* <Route path="contact"     element={<Contact />} /> */}
          <Route path="*"           element={<TemplateStd page="home" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}