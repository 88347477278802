import { useRef } from 'react'
import { useControls, folder } from 'leva'
import { Perf } from 'r3f-perf'
import { Sky, Clouds, Cloud, Preload } from '@react-three/drei'
import * as THREE from 'three'
import { useThree, useFrame } from '@react-three/fiber'

import '../../App.css'

import useLightStore from '../../stores/useLight.jsx'
import useDebugStore from '../../stores/useDebug.jsx'
import useIntroStore from '../../stores/useIntro.jsx'
import useMainStore from '../../stores/useMain.jsx'

import Heart from './Heart.jsx'
import Sphere from './Sphere.jsx'
import MyCamera from './MyCamera.jsx'
import Sea from './Sea.jsx'
import Island from './Island.jsx'
import Main from './Main.jsx'

import MotionBlur from '../../effects/MotionBlur.jsx'
import Rig from '../../effects/Rig.jsx'

// ExperienceIntro function
export default function ExperienceIntro() {

    const isDebug         = useDebugStore((state) => state.isDebug)
    const isIntro         = useIntroStore((state) => state.isIntro)
    const isMain          = useMainStore((state) => state.isMain)

    const lightX          = useLightStore((state) => state.lightX)
    const lightY          = useLightStore((state) => state.lightY)
    const lightZ          = useLightStore((state) => state.lightZ)
    
    const sunDistance     = useLightStore((state) => state.sunDistance)
    const cloudsPositionY = useLightStore((state) => state.cloudsPositionY)
    
    const roughnessOuter = 0.3,
          lightIntensity = 2,
          ambientLight = 0.5,
          contactY = -0.09

    // LEVA CONTROLS
/*     const { _roughnessOuter, displayPerf } = isDebug ? useControls( {
        _roughnessOuter: {
            value: roughnessOuter,
            min: 0,
            max: 1
        },
        displayPerf: false
    }) : 0 */
    const displayPerf = false
/*     const {
      isTestLight,
      testLightX, testLightY, testLightZ,
      _lightIntensity,
      _ambientLight,
      _contactY
    } = isDebug
          ? useControls(
            {
              'Light': folder(
                {
                  isTestLight: false,
                  'Directional Light': folder(
                    {
                      testLightX: {
                        value: lightX,//0.6
                        min: -20,
                        max: 50
                      },
                      testLightY: {
                        value: lightY,//2.6
                        min: -10,
                        max: 10
                      },
                      testLightZ: {
                        value: lightZ,//-4.4
                        min: -20,
                        max: 50
                      },
                      _lightIntensity: {
                        value: lightIntensity,
                        min: 0,
                        max: 5
                      },
                    }
                  ),
                  _ambientLight: {
                    value: ambientLight,
                    min: 0,
                    max: 5
                  },
                  _contactY: {
                    value: contactY,
                    min: -2,
                    max: 5,
                    step: 0.01
                  } 
                },
                { collapsed: true }
                  
              )
            })
          : 0 */

    // LIGHT HELPER
    const directionalLightRef = useRef()
    /* useHelper(directionalLightRef, THREE.DirectionalLightHelper, 1) */
    
    // RETURN
    return (
      <>
            {displayPerf ? <Perf position="top-left"/> : null}

            
            <MyCamera />

            <Rig />

            {isIntro &&
              (
                <>
                  
                  <Sky sunPosition={ [ lightX, lightY, lightZ ] } distance={sunDistance}/>
                  
                  <directionalLight
                    ref={ directionalLightRef }
                    position={ [ lightX, lightY, lightZ ]}
                    intensity={ lightIntensity }
                    castShadow
                  />
                  <ambientLight intensity={ ambientLight }/>

                  {//
                   // Most intro animations and logics start from Island component
                  }
                  <Island />

                  <Sea/>

                  {/* <Clouds position={[0,cloudsPositionY,-50]} material={THREE.MeshBasicMaterial}>
                    <Cloud segments={50} bounds={[100, 2, 100]} volume={25} speed={0.6} fade={100} color="#ffffff" />
                    <Cloud segments={50} bounds={[100, 2, 100]} volume={40} speed={0.3} fade={100} color="#eeeeee" />
                  </Clouds> */}

                  {/* <MotionBlur/> */}

                  <Preload all />
                  
                </>
              )
            }
            {/* {isMain &&
              (
                <Suspense fallback={null}>
                                    
                  <ambientLight intensity={ ambientLight }/>

                  <Main />
                  
                </Suspense>
              )
            } */}

            {/* <SoftShadows samples={30}/> */}
            {/* <BakeShadows /> */}
            {/* <AccumulativeShadows
                position={ [0, -0.09, 0] }
                scale={ 10 }
                color='#AA921E'
                opacity={ 1 }
                frames={ Infinity }
                temporal
                blend={ 150 }
            >
                <RandomizedLight
                    amount={ 8 }
                    radius={ 1 }
                    ambient={ 0.4}
                    intensity={ 2 }
                    position={ [lightX, lightY, lightZ] }
                    bias={ 0.001 }
                    castShadow
                />
            </AccumulativeShadows> */}
            {/* <ContactShadows
                position={ [0, contactY, 0] }
                color='#AA921E'
                opacity={ 1 }
                blur={ 0.5 }    
            /> */}
            
            {/* {data.map((praline, i) => 

                <praline.shape
                    key={i}
                    position={[0, 0, data.length * -0.25]}
                    scale={adjustHeartForScreenSize()}
                    rotation={[0, 0, 0]}
                    rotationYAroundCenter={Math.PI * 2 / data.length * i}
                    colorOuter={praline.color}
                    roughnessOuter = {roughnessOuter}
                />
                
            )}

            <Logo /> */}


            {/* <Environment preset="dawn"/> */}

            
        </>
    )
}
















// MOCK DATA
const data = [
    {
      inStock: 500,
      color: "green",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 0,
      color: "black",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 500,
      color: "pink",
      width: 1,
      shape: Sphere,
    },
    {
      inStock: 500,
      color: "yellow",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 500,
      color: "brown",
      width: 1,
      shape: Sphere,
    },
    {
      inStock: 500,
      color: "blue",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 1,
      color: "red",
      width: 1,
      shape: Heart,
    },{
      inStock: 500,
      color: "green",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 0,
      color: "black",
      width: 1,
      shape: Sphere,
    },
    {
      inStock: 500,
      color: "pink",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 500,
      color: "yellow",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 500,
      color: "brown",
      width: 1,
      shape: Sphere,
    },
    {
      inStock: 500,
      color: "blue",
      width: 1,
      shape: Heart,
    },
    {
      inStock: 1,
      color: "red",
      width: 1,
      shape: Sphere,
    },
]





















