import { create } from 'zustand'
    
const cameraStore = (set) => ({

    // CAMERA CONTROLS
    cameraMouseControl: true,
    cameraPosX:     0  ,
    cameraPosY:     0  ,
    cameraPosZ:     5    ,
    cameraLookAtX:  0  ,
    cameraLookAtY:  0  ,
    cameraLookAtZ:  0    ,

    // SETTERS
    setCameraPosX: (value) => set({ cameraPosX: value }),
    setCameraPosY: (value) => set({ cameraPosY: value }),
    setCameraPosZ: (value) => set({ cameraPosZ: value }),
    setCameraLookAtX: (value) => set({ cameraLookAtX: value }),
    setCameraLookAtY: (value) => set({ cameraLookAtY: value }),
    setCameraLookAtZ: (value) => set({ cameraLookAtZ: value }),
    setCamera: (value) => set((state) => ({
        cameraPosX: typeof value.posX !== "undefined" ? value.posX : state.cameraPosX,
        cameraPosY: typeof value.posY !== "undefined" ? value.posY : state.cameraPosY,
        cameraPosZ: typeof value.posZ !== "undefined" ? value.posZ : state.cameraPosZ,
        cameraLookAtX: typeof value.lookAtX !== "undefined" ? value.lookAtX : state.cameraLookAtX,
        cameraLookAtY: typeof value.lookAtY !== "undefined" ? value.lookAtY : state.cameraLookAtY,
        cameraLookAtZ: typeof value.lookAtZ !== "undefined" ? value.lookAtZ : state.cameraLookAtZ,
    })),


    // EXAMPLES
/*     bears: 0,
    increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
    removeAllBears: () => set({ bears: 0 }),
    setBears: (value) => set({ bears: value }), */

    
})

const useCameraStore = create(cameraStore)

export default useCameraStore